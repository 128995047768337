import React, { useState } from 'react';
import PT from 'prop-types';
import {
  Box,
  Button,
  Flex,
  Grid,
  H2,
  H3,
  RadioButton,
  Switch,
  Toast,
  Typography,
} from '@procore/core-react';
import Form from '@/react/shared/form';
import QuestionnaireModal from './QuestionnaireModal';
import { MARKETPLACE_TABS } from '../constants';
import { Divider, InlineLink } from '../utils';
import { ClipboardBulletedChecks, ShieldStar } from '@procore/core-icons';
import {
  CountrySelect,
  MultiCountrySelect,
  SecurityStandards,
  SSOSelect,
} from '../components';
import { useToastAlertContext } from '@procore/toast-alert';

const TabSecurity = ({
  I18n,
  fields,
  isDisabled,
  onFieldChange,
  FormLabel,
  countries,
  securityStandards,
  validations,
  ssoProviders,
}) => {
  const i18Prefix = 'labels.marketplaceApp.securityCompliance';
  const onChange = (fieldPath, value) =>
    onFieldChange([MARKETPLACE_TABS.SECURITY, ...fieldPath], value);
  const [isModalDisplayed, setIsModalDisplayed] = useState(false);
  const { showToast } = useToastAlertContext();

  return (
    <>
      <Grid.Row>
        <Grid.Col colWidth={10}>
          <Flex alignItems="flex-start" gap="md">
            <ShieldStar />

            <Box display="block">
              <H2>{I18n.t(`${i18Prefix}.header`)}</H2>
              <Typography intent="body" color="gray45">
                {I18n.t(`${i18Prefix}.subHeader`)}
                <InlineLink
                  className="override-default"
                  href="https://support.procore.com/faq/what-is-the-security-and-trust-self-certified-badge"
                  target="_blank"
                >
                  {I18n.t(`${i18Prefix}.learnMore`)}
                </InlineLink>
              </Typography>
            </Box>
          </Flex>
        </Grid.Col>
        <Grid.Col colWidth={2}>
          <Button
            data-testid="marketplace-security-review-questions"
            onClick={() => setIsModalDisplayed(true)}
            variant="secondary"
          >
            {I18n.t(`${i18Prefix}.reviewQuestions`)}
          </Button>
        </Grid.Col>
      </Grid.Row>

      <Grid.Row>
        <Grid.Col
          colWidth={12}
          data-testid="marketplace-security-questionnaire-option"
        >
          <Box
            display="flex-column"
            data-testid="marketplace-security-questionnaire-option-title"
            marginBottom="md"
          >
            <Typography intent="label">
              {I18n.t(`${i18Prefix}.tabTitle`)}
            </Typography>
            <Typography
              intent="small"
              color="gray45"
              style={{ marginTop: '4px' }}
            >
              {I18n.t(`${i18Prefix}.toggle`)}
            </Typography>
          </Box>
          <Flex direction="row" gap="sm">
            <Switch
              data-testid="marketplace-security-questionnaire-option-switch"
              checked={fields?.security_questionnaire_visible}
              onChange={(e) => {
                showToast.success(I18n.t(`${i18Prefix}.${
                  e.target.checked ? 'toggleSecurityEnabled' : 'toggleSecurityDisabled'
                }`));
                onChange(
                  ['security_questionnaire_visible'],
                  !fields?.security_questionnaire_visible
                );
              }}
            />
            <Typography>
              {I18n.t(
                `${i18Prefix}.${
                  fields?.security_questionnaire_visible
                    ? 'toggleDisabled'
                    : 'toggleEnabled'
                }`
              )}
            </Typography>
          </Flex>
        </Grid.Col>
      </Grid.Row>
      {fields?.security_questionnaire_visible ? (
        <>
          <Grid.Row data-testid="marketplace-security-questionnaire">
            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col>
              <Flex alignItems="flex-start" gap="md">
                <ClipboardBulletedChecks />

                <Box display="block">
                  <H2>{I18n.t(`${i18Prefix}.questionnaireTitle`)}</H2>
                  <Typography intent="body" color="gray45">
                    {I18n.t(`${i18Prefix}.description`)}
                  </Typography>
                </Box>
              </Flex>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col colWidth={12}>
              <H3>{I18n.t(`${i18Prefix}.companyInformation`)}</H3>
            </Grid.Col>
            <Grid.Col colWidth={6}>
              <Form.Field
                data-testid="company-legal-name"
                required
                disabled={isDisabled}
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'company_legal_name',
                    ]}
                    contentI18nTag="securityCompliance.companyName"
                    copyText={
                      fields?.security_questionnaire?.company_legal_name
                    }
                    showInfo={false}
                  />
                }
                type="text"
                value={fields?.security_questionnaire?.company_legal_name}
                onChange={(e) =>
                  onChange(
                    ['security_questionnaire', 'company_legal_name'],
                    e.target.value
                  )
                }
                placeholder={I18n.t('placeholders.name')}
                errors={validations?.security_questionnaire?.company_legal_name}
              />
            </Grid.Col>
            <Grid.Col colWidth={6}>
              <CountrySelect
                countries={countries}
                field={fields?.security_questionnaire?.headquarters_country}
                name="company_headquarters"
                label="securityCompliance.headquarters"
                I18n={I18n}
                onChange={(value) =>
                  onChange(
                    ['security_questionnaire', 'headquarters_country_id'],
                    value
                  )
                }
                isDisabled={isDisabled}
                FormLabel={FormLabel}
                errors={
                  validations?.security_questionnaire?.headquarters_country_id
                }
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <H3>{I18n.t(`${i18Prefix}.privacy`)}</H3>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <MultiCountrySelect
                name="data_processing_locations"
                label="securityCompliance.dataProcessing"
                I18n={I18n}
                onChange={(value) =>
                  onChange(
                    ['security_questionnaire', 'data_processing_country_ids'],
                    value
                  )
                }
                isDisabled={isDisabled}
                FormLabel={FormLabel}
                countries={countries}
                field={
                  fields?.security_questionnaire?.data_processing_countries
                }
                errors={
                  validations?.security_questionnaire
                    ?.data_processing_country_ids
                }
              />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_subprocessors',
                    ]}
                    contentI18nTag="securityCompliance.subProcessors"
                    showInfo
                  />
                }
                errors={validations?.security_questionnaire?.has_subprocessors}
              >
                <RadioButton
                  name="subProcessors"
                  data-testid="subProcessors-yes"
                  checked={fields?.security_questionnaire?.has_subprocessors}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_subprocessors'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="subProcessors"
                  data-testid="subProcessors-no"
                  checked={
                    fields?.security_questionnaire?.has_subprocessors === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_subprocessors'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.Textarea
                required
                data-testid="subprocessors_details"
                disabled={
                  isDisabled ||
                  !fields?.security_questionnaire?.has_subprocessors
                }
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'subprocessors_details',
                    ]}
                    contentI18nTag="securityCompliance.subProcessorsDetails"
                    copyText={
                      fields?.security_questionnaire?.subprocessors_details
                    }
                    showInfo={false}
                  />
                }
                type="text"
                value={fields?.security_questionnaire?.subprocessors_details}
                onChange={(e) =>
                  onChange(
                    ['security_questionnaire', 'subprocessors_details'],
                    e.target.value
                  )
                }
                placeholder={I18n.t(
                  'placeholders.marketplaceApp.subProcessorsDetails'
                )}
                errors={
                  validations?.security_questionnaire?.subprocessors_details
                }
              />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_sensitive_data',
                    ]}
                    contentI18nTag="securityCompliance.sensitiveData"
                    showInfo={false}
                  />
                }
                errors={validations?.security_questionnaire?.has_sensitive_data}
              >
                <RadioButton
                  name="sensitiveData"
                  data-testid="has_sensitive_data_yes"
                  checked={fields?.security_questionnaire?.has_sensitive_data}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_sensitive_data'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="sensitiveData"
                  data-testid="has_sensitive_data_no"
                  checked={
                    fields?.security_questionnaire?.has_sensitive_data === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_sensitive_data'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.Textarea
                required
                data-testid="sensitive_data_handling_details"
                disabled={
                  isDisabled ||
                  !fields?.security_questionnaire?.has_sensitive_data
                }
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'sensitive_data_handling_details',
                    ]}
                    contentI18nTag="securityCompliance.sensitiveDataDetails"
                    copyText={
                      fields?.security_questionnaire
                        ?.sensitive_data_handling_details
                    }
                    showInfo={false}
                  />
                }
                type="text"
                value={
                  fields?.security_questionnaire
                    ?.sensitive_data_handling_details
                }
                onChange={(e) =>
                  onChange(
                    [
                      'security_questionnaire',
                      'sensitive_data_handling_details',
                    ],
                    e.target.value
                  )
                }
                placeholder={I18n.t(
                  'placeholders.marketplaceApp.sensitiveDataDetails'
                )}
                errors={
                  validations?.security_questionnaire
                    ?.sensitive_data_handling_details
                }
              />
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <H3>{I18n.t(`${i18Prefix}.securityPractices`)}</H3>
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_security_contact',
                    ]}
                    contentI18nTag="securityCompliance.hasSecurityContact"
                    showInfo
                  />
                }
                errors={
                  validations?.security_questionnaire?.has_security_contact
                }
              >
                <RadioButton
                  name="hasSecurityContact"
                  data-testid="hasSecurityContact-yes"
                  checked={fields?.security_questionnaire?.has_security_contact}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_security_contact'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasSecurityContact"
                  data-testid="hasSecurityContact-no"
                  checked={
                    fields?.security_questionnaire?.has_security_contact ===
                    false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_security_contact'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>
            <Grid.Col colWidth={6}>
              <Form.Field
                data-testid="security-email"
                required
                disabled={
                  isDisabled ||
                  !fields?.security_questionnaire?.has_security_contact
                }
                label={
                  <FormLabel
                    changeFields={['security_questionnaire', 'security_email']}
                    contentI18nTag="securityCompliance.securityEmailContact"
                    copyText={fields?.security_questionnaire?.security_email}
                    showInfo={false}
                  />
                }
                type="email"
                value={fields?.security_questionnaire?.security_email}
                onChange={(e) =>
                  onChange(
                    ['security_questionnaire', 'security_email'],
                    e.target.value
                  )
                }
                placeholder={I18n.t('placeholders.contact.email')}
                errors={validations?.security_questionnaire?.security_email}
              />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_security_audits',
                    ]}
                    contentI18nTag="securityCompliance.hasSecurityAudits"
                    showInfo={false}
                  />
                }
                errors={
                  validations?.security_questionnaire?.has_security_audits
                }
              >
                <RadioButton
                  name="hasSecurityAudits"
                  data-testid="hasSecurityAudits-yes"
                  checked={fields?.security_questionnaire?.has_security_audits}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_security_audits'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasSecurityAudits"
                  data-testid="hasSecurityAudits-no"
                  checked={
                    fields?.security_questionnaire?.has_security_audits ===
                    false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_security_audits'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_security_standards',
                    ]}
                    contentI18nTag="securityCompliance.hasSecurityStandards"
                    showInfo={false}
                  />
                }
                errors={
                  validations?.security_questionnaire?.has_security_standards
                }
              >
                <RadioButton
                  name="hasSecurityStandards"
                  data-testid="hasSecurityStandards-yes"
                  checked={
                    fields?.security_questionnaire?.has_security_standards
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_security_standards'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasSecurityStandards"
                  data-testid="hasSecurityStandards-no"
                  checked={
                    fields?.security_questionnaire?.has_security_standards ===
                    false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_security_standards'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <SecurityStandards
                name="security_standards"
                label="securityCompliance.selectSecurityStandards"
                I18n={I18n}
                onChange={(value) =>
                  onChange(
                    ['security_questionnaire', 'security_standard_ids'],
                    value
                  )
                }
                isDisabled={
                  isDisabled ||
                  !fields?.security_questionnaire?.has_security_standards
                }
                FormLabel={FormLabel}
                securityStandards={securityStandards}
                errors={
                  validations?.security_questionnaire?.security_standard_ids
                }
                field={fields?.security_questionnaire?.security_standards}
              />
              <Box marginTop="lg">
                <Form.Field
                  data-testid="other-security-standards"
                  disabled={
                    isDisabled ||
                    !fields?.security_questionnaire?.has_security_standards
                  }
                  label={
                    <FormLabel
                      changeFields={[
                        'security_questionnaire',
                        'other_security_standards',
                      ]}
                      contentI18nTag="securityCompliance.otherSecurityStandards"
                      copyText={
                        fields?.security_questionnaire?.other_security_standards
                      }
                    />
                  }
                  type="text"
                  value={
                    fields?.security_questionnaire?.other_security_standards
                  }
                  onChange={(e) =>
                    onChange(
                      ['security_questionnaire', 'other_security_standards'],
                      e.target.value
                    )
                  }
                  placeholder={I18n.t(
                    'placeholders.marketplaceApp.otherSecurityStandards'
                  )}
                />
              </Box>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_penetration_testing',
                    ]}
                    contentI18nTag="securityCompliance.hasPenetrationTesting"
                    showInfo={false}
                  />
                }
                errors={
                  validations?.security_questionnaire?.has_penetration_testing
                }
              >
                <RadioButton
                  name="hasPenetrationTesting"
                  data-testid="hasPenetrationTesting-yes"
                  checked={
                    fields?.security_questionnaire?.has_penetration_testing
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_penetration_testing'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasPenetrationTesting"
                  data-testid="hasPenetrationTesting-no"
                  checked={
                    fields?.security_questionnaire?.has_penetration_testing ===
                    false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_penetration_testing'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'can_notify_procore',
                    ]}
                    contentI18nTag="securityCompliance.canNotifyProcore"
                    showInfo={false}
                  />
                }
                errors={validations?.security_questionnaire?.can_notify_procore}
              >
                <RadioButton
                  name="canNotifyProcore"
                  data-testid="canNotifyProcore-yes"
                  checked={fields?.security_questionnaire?.can_notify_procore}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'can_notify_procore'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="canNotifyProcore"
                  data-testid="canNotifyProcore-no"
                  checked={
                    fields?.security_questionnaire?.can_notify_procore === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'can_notify_procore'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_disclosure_program',
                    ]}
                    contentI18nTag="securityCompliance.hasDisclosureProgram"
                    showInfo={false}
                  />
                }
                errors={
                  validations?.security_questionnaire?.has_disclosure_program
                }
              >
                <RadioButton
                  name="hasDisclosureProgram"
                  data-testid="hasDisclosureProgram-yes"
                  checked={
                    fields?.security_questionnaire?.has_disclosure_program
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_disclosure_program'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasDisclosureProgram"
                  data-testid="hasDisclosureProgram-no"
                  checked={
                    fields?.security_questionnaire?.has_disclosure_program ===
                    false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_disclosure_program'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.Field
                data-testid="disclosure_program_url"
                required
                disabled={
                  isDisabled ||
                  !fields?.security_questionnaire?.has_disclosure_program
                }
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'disclosure_program_url',
                    ]}
                    contentI18nTag="securityCompliance.disclosureProgramURL"
                    copyText={
                      fields?.security_questionnaire?.disclosure_program_url
                    }
                    showInfo={false}
                  />
                }
                type="text"
                value={fields?.security_questionnaire?.disclosure_program_url}
                onChange={(e) =>
                  onChange(
                    ['security_questionnaire', 'disclosure_program_url'],
                    e.target.value
                  )
                }
                placeholder={I18n.t('placeholders.url')}
                errors={
                  validations?.security_questionnaire?.disclosure_program_url
                }
              />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_bug_bounty_program',
                    ]}
                    contentI18nTag="securityCompliance.hasBugBounty"
                    showInfo={false}
                  />
                }
                errors={
                  validations?.security_questionnaire?.has_bug_bounty_program
                }
              >
                <RadioButton
                  name="hasBugBounty"
                  data-testid="hasBugBounty-yes"
                  checked={
                    fields?.security_questionnaire?.has_bug_bounty_program
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_bug_bounty_program'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasBugBounty"
                  data-testid="hasBugBounty-no"
                  checked={
                    fields?.security_questionnaire?.has_bug_bounty_program ===
                    false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_bug_bounty_program'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>
          </Grid.Row>

          <Grid.Row>
            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <H3>{I18n.t(`${i18Prefix}.applicationSecurityFeatures`)}</H3>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_data_encryption_at_rest',
                    ]}
                    contentI18nTag="securityCompliance.hasDataEncryptionAtRest"
                    showInfo={false}
                  />
                }
                errors={
                  validations?.security_questionnaire
                    ?.has_data_encryption_at_rest
                }
              >
                <RadioButton
                  name="hasDataEncryptionAtRest"
                  data-testid="hasDataEncryptionAtRest-yes"
                  checked={
                    fields?.security_questionnaire?.has_data_encryption_at_rest
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_data_encryption_at_rest'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasDataEncryptionAtRest"
                  data-testid="hasDataEncryptionAtRest-no"
                  checked={
                    fields?.security_questionnaire
                      ?.has_data_encryption_at_rest === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_data_encryption_at_rest'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_data_encryption_in_transit',
                    ]}
                    contentI18nTag="securityCompliance.hasDataEncryptionInTransit"
                    showInfo={false}
                  />
                }
                errors={
                  validations?.security_questionnaire
                    ?.has_data_encryption_in_transit
                }
              >
                <RadioButton
                  name="hasDataEncryptionInTransit"
                  data-testid="hasDataEncryptionInTransit-yes"
                  checked={
                    fields?.security_questionnaire
                      ?.has_data_encryption_in_transit
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      [
                        'security_questionnaire',
                        'has_data_encryption_in_transit',
                      ],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasDataEncryptionInTransit"
                  data-testid="hasDataEncryptionInTransit-no"
                  checked={
                    fields?.security_questionnaire
                      ?.has_data_encryption_in_transit === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      [
                        'security_questionnaire',
                        'has_data_encryption_in_transit',
                      ],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={['security_questionnaire', 'has_sso_support']}
                    contentI18nTag="securityCompliance.hasSSOSupport"
                    showInfo={false}
                  />
                }
                errors={validations?.security_questionnaire?.has_sso_support}
              >
                <RadioButton
                  name="hasSSOSupport"
                  data-testid="hasSSOSupport-yes"
                  checked={fields?.security_questionnaire?.has_sso_support}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_sso_support'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasSSOSupport"
                  data-testid="hasSSOSupport-no"
                  checked={
                    fields?.security_questionnaire?.has_sso_support === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_sso_support'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={6}>
              <SSOSelect
                name="sso_providers"
                label="securityCompliance.ssoProviders"
                onChange={(value) =>
                  onChange(['security_questionnaire', 'sso_providers'], value)
                }
                isDisabled={
                  isDisabled || !fields?.security_questionnaire?.has_sso_support
                }
                I18n={I18n}
                FormLabel={FormLabel}
                ssoOptions={ssoProviders}
                field={fields?.security_questionnaire?.sso_providers}
                errors={validations?.security_questionnaire?.sso_providers}
              />
              <Box marginTop="lg">
                <Form.Field
                  data-testid="other-sso-providers"
                  disabled={
                    isDisabled ||
                    !fields?.security_questionnaire?.has_sso_support
                  }
                  label={
                    <FormLabel
                      changeFields={[
                        'security_questionnaire',
                        'other_sso_providers',
                      ]}
                      contentI18nTag="securityCompliance.otherSSOProviders"
                      copyText={
                        fields?.security_questionnaire?.other_sso_providers
                      }
                    />
                  }
                  type="text"
                  value={fields?.security_questionnaire?.other_sso_providers}
                  onChange={(e) =>
                    onChange(
                      ['security_questionnaire', 'other_sso_providers'],
                      e.target.value
                    )
                  }
                  placeholder={I18n.t(
                    'placeholders.marketplaceApp.otherSSOProviders'
                  )}
                />
              </Box>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={[
                      'security_questionnaire',
                      'has_saml_support',
                    ]}
                    contentI18nTag="securityCompliance.hasSAMLSupport"
                    showInfo={false}
                  />
                }
                errors={validations?.security_questionnaire?.has_saml_support}
              >
                <RadioButton
                  name="hasSAMLSupport"
                  data-testid="hasSAMLSupport-yes"
                  checked={fields?.security_questionnaire?.has_saml_support}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_saml_support'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasSAMLSupport"
                  data-testid="hasSAMLSupport-no"
                  checked={
                    fields?.security_questionnaire?.has_saml_support === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_saml_support'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={['security_questionnaire', 'has_mfa_support']}
                    contentI18nTag="securityCompliance.hasMFASupport"
                    showInfo={false}
                  />
                }
                errors={validations?.security_questionnaire?.has_mfa_support}
              >
                <RadioButton
                  name="hasMFASupport"
                  data-testid="hasMFASupport-yes"
                  checked={fields?.security_questionnaire?.has_mfa_support}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_mfa_support'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasMFASupport"
                  data-testid="hasMFASupport-no"
                  checked={
                    fields?.security_questionnaire?.has_mfa_support === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_mfa_support'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Divider />
            </Grid.Col>

            <Grid.Col colWidth={12}>
              <Form.FieldWrapper
                required
                label={
                  <FormLabel
                    changeFields={['security_questionnaire', 'has_fas_support']}
                    contentI18nTag="securityCompliance.hasFASSupport"
                    showInfo={false}
                  />
                }
                errors={validations?.security_questionnaire?.has_fas_support}
              >
                <RadioButton
                  name="hasFASSupport"
                  data-testid="hasFASSupport-yes"
                  checked={fields?.security_questionnaire?.has_fas_support}
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_fas_support'],
                      true
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.yes')}
                  </Typography>
                </RadioButton>
                <RadioButton
                  name="hasFASSupport"
                  data-testid="hasFASSupport-no"
                  checked={
                    fields?.security_questionnaire?.has_fas_support === false
                  }
                  disabled={isDisabled}
                  onChange={() =>
                    onChange(
                      ['security_questionnaire', 'has_fas_support'],
                      false
                    )
                  }
                >
                  <Typography intent="body" weight="regular">
                    {I18n.t('globalLabels.no')}
                  </Typography>
                </RadioButton>
              </Form.FieldWrapper>
            </Grid.Col>
          </Grid.Row>
        </>
      ) : null}
      {isModalDisplayed ? (
        <QuestionnaireModal
          data-testid="questionnaire-modal-container"
          close={() => setIsModalDisplayed(false)}
        />
      ) : null}
    </>
  );
};

TabSecurity.propTypes = {
  I18n: PT.shape({
    t: PT.func,
  }).isRequired,
  fields: PT.shape({
    security_questionnaire_visible: PT.bool,
    security_questionnaire: PT.shape({
      company_legal_name: PT.string,
      company_headquarters: PT.string,
      has_subprocessors: PT.bool,
      subprocessors_details: PT.string,
      has_sensitive_data: PT.bool,
      sensitive_data_handling_details: PT.string,
      has_security_contact: PT.bool,
      security_email: PT.string,
      has_security_audits: PT.bool,
      has_security_standards: PT.bool,
      other_security_standards: PT.string,
      has_penetration_testing: PT.bool,
      can_notify_procore: PT.bool,
      has_disclosure_program: PT.bool,
      disclosure_program_url: PT.string,
      has_bug_bounty_program: PT.bool,
      has_data_encryption_at_rest: PT.bool,
      has_data_encryption_in_transit: PT.bool,
      has_sso_support: PT.bool,
      has_saml_support: PT.bool,
      has_mfa_support: PT.bool,
      has_fas_support: PT.bool,
    }),
  }).isRequired,
  countries: PT.arrayOf(
    PT.shape({
      id: PT.string,
      code: PT.string,
    })
  ).isRequired,
  onFieldChange: PT.func.isRequired,
  isDisabled: PT.bool,
  FormLabel: PT.elementType.isRequired,
  securityStandards: PT.arrayOf(PT.shape({})).isRequired,
  validations: PT.shape({
    security_questionnaire: PT.shape({
      company_legal_name: PT.string,
      company_headquarters: PT.string,
      data_processing_locations: PT.arrayOf(PT.string),
      has_subprocessors: PT.bool,
      subprocessors_details: PT.string,
      has_sensitive_data: PT.bool,
      sensitive_data_handling_details: PT.string,
      has_security_contact: PT.bool,
      security_email: PT.string,
      has_security_audits: PT.bool,
      has_security_standards: PT.bool,
      security_standards: PT.arrayOf(PT.string),
      other_security_standards: PT.string,
      has_penetration_testing: PT.bool,
      can_notify_procore: PT.bool,
      has_disclosure_program: PT.bool,
      disclosure_program_url: PT.string,
      has_bug_bounty_program: PT.bool,
      has_data_encryption_at_rest: PT.bool,
      has_data_encryption_in_transit: PT.bool,
      has_sso_support: PT.bool,
      sso_providers: PT.arrayOf(PT.string),
      other_sso_providers: PT.string,
      has_saml_support: PT.bool,
      has_mfa_support: PT.bool,
      has_fas_support: PT.bool,
    }),
  }),
};

export default TabSecurity;
