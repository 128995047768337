import React, { useEffect, useState } from 'react';
import PT from 'prop-types';
import { filter, isEmpty, path, pathOr } from 'ramda';
import {
  Banner,
  Box,
  Button,
  Card,
  ErrorBanner,
  InfoBanner,
  Spinner,
  Tabs,
  useI18nContext,
  Grid,
  Flex,
} from '@procore/core-react';
import MetricsGraph from '@/react/common/Metrics/MetricsGraph';
import { trackEvent } from '@/react/shared/analytics';
import {
  DEVELOPER_APP_PT,
  MANIFEST_PT,
  METRICS_DATA_PT,
  PRODUCT_TOOLS_PT,
  REGIONS_PT,
  COUNTRIES_PT,
} from '@/react/shared/developerAppShowPropTypes';
import { UseShowContext } from '../Show.context';

// eslint-disable-next-line complexity
const MetricsCard = () => {
  const {
    developerAppId,
    metricsData,
    developerAppMetricsPath,
    state: { devApp },
  } = UseShowContext();

  const I18n = useI18nContext();

  const { app_installations, developer_app_id, ...aggregatesData } =
    metricsData;

  return (
    <Card>
      <Box>
        <Grid>
          <Grid.Row>
            <Grid.Col>
              <Flex justifyContent="flex-end">
                <Box padding="md">
                  <Button
                    onClick={() => {
                      trackEvent('developers.app_metrics.view_report.clicked', {
                        developer_app_id: developerAppId,
                        developer_app_name: devApp.internal_name,
                      });
                      window.location = developerAppMetricsPath;
                    }}
                  >
                    {I18n.t('viewMetricsReport')}
                  </Button>
                </Box>
              </Flex>
            </Grid.Col>
          </Grid.Row>
        </Grid>

        <MetricsGraph
          I18n={I18n}
          metricsData={aggregatesData}
          showBorder={false}
        />
      </Box>
    </Card>
  );
};

MetricsCard.propTypes = {
  developerAppId: PT.string.isRequired,
  I18n: PT.shape({
    t: PT.func.isRequired,
  }).isRequired,
  metricsData: PT.shape(METRICS_DATA_PT.TYPES),
  developerAppMetricsPath: PT.string,
};

MetricsCard.defaultProps = {
  metricsData: PT.shape(METRICS_DATA_PT.DEFAULT),
  developerAppMetricsPath: '',
};

export default MetricsCard;
